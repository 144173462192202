import React from 'react';
import { authenticationService } from '_services/authentication.service';
import 'LoginPage/LogInOutPage.css';

class LogoutPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleLoad = this.handleLoad.bind(this);
    }

    componentDidMount() {
        window.addEventListener('load', this.handleLoad);
        this.handleLoad();
    }

    componentDidUpdate() {
        window.addEventListener('load', this.handleLoad);
    }

    componentWillUnmount() {
        window.removeEventListener('load', this.handleLoad);
    }


    handleLoad() {
        document.getElementById('status').innerText = "Logging you out...";
        authenticationService.removeLocalUser();
        document.getElementById('status').innerText = "You've logged out";
    }

    

    render() {
        return (
            <div className="logout">
                <h1 className="logout-title">Logout</h1>
                <h2 id="status" className="status">{authenticationService.currentUserValue?"You're logged in":"You've logged out"}</h2>
                <div hidden={!authenticationService.currentUserValue}>
                    <a target="_self" href={authenticationService.getOauthLogoutURL()}> Click here to Logout</a>
                </div>
            </div>
        )
    }
}

export { LogoutPage }; 