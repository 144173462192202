import React, {Component} from 'react';
import { authenticationService } from '_services/authentication.service';
import 'components/Home.css';

export default class Home extends Component{
	constructor(props){
		super(props);
		this.state = {
			currentUser: authenticationService.currentUserValue
		}
	}
    componentDidMount() {
        // userService.getAll().then(users => this.setState({ users }));
	}
	render(){
		return(
			<div className="home-div">
				<p className="home-title">Welcome!<br></br></p>
				<p className="home-description">
					Secur-IT-Baseliner allows users to quickly view the required NIST 800-53 based <a href="/authed/SecurityControls"><b>Security Controls</b></a> for a system based on its categorization and overlay selection.<br/><br/>
					The selected controls may be exported to a file format that can be imported into other tools.<br/><br/>
					Secur-IT-Baseliner also provides a <a href="authed/Questionnaire"><b>Questionnaire</b></a> to help users categorize their systems and select the appropriate overlays.
				</p>
				<p className="company-name">I N V I C T U S</p>
				<p className="company-organization">Software Engineering Group</p>
			</div>
		)
	}
}