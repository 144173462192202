

import queryString from 'query-string';

export const queryStringHelper = {
    getQueryString,
    getState,
    getFromPath,
    getFromPathwithState,
};

function getQueryString(defualt = null) {
    if (window.location.search && window.location.search.length > 0) {
        return queryString.parse(window.location.search);
    }
    return defualt;
}

function getState(queryString = null, defualt = null) {
    if (queryString == null) {
        queryString = getQueryString({});
    }
    if (queryString.state) {
        return decodeURIComponent(queryString.state);
    }
    return defualt;
}

//note fromPath being set as full state currently
//assumes state already decoded
function getFromPathwithState(state = null, defualt = null) {
    if (state) {
        return state;
    }
    return defualt;
}

//note fromPath being set as full state currently
function getFromPath(defualt = null) {
    return getFromPathwithState(getQueryString({}).state, defualt);
}