import React from 'react';
import { history } from '_helpers/history';
import { queryStringHelper } from '_helpers/query-string';
import { authenticationService } from '_services/authentication.service';
import 'LoginPage/LogInOutPage.css';

class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        if (this.state === 'undefined' || this.state == null) {
            this.state = {};
        }
        this.state.auth_code = '';
        this.state.pased_state = '{}';
        this.handleLoad = this.handleLoad.bind(this);
        this.getTokenFromCode = this.getTokenFromCode.bind(this);
    }

    componentDidMount() {
        window.addEventListener('load', this.handleLoad);
        this.handleLoad();
    }

    componentDidUpdate() {
        window.addEventListener('load', this.handleLoad);
    }

    componentWillUnmount() {
        window.removeEventListener('load', this.handleLoad);
    }

    handleLoad() {
        let codeVal = '';
        let qStr = queryStringHelper.getQueryString({});
        if (qStr.code) {
            codeVal = qStr.code;
        }
        let rState = queryStringHelper.getState(qStr);
        if (codeVal !== "" && codeVal !== this.state.auth_code) {
            this.getTokenFromCode(codeVal, rState);
        }
        this.setState({
            auth_code: codeVal,
            pased_state: rState
        });
    }

    getTokenFromCode(code, state) {
        let fromPath = queryStringHelper.getFromPathwithState(state, null);
        document.getElementById('status').innerText = "Getting Tokens...";
        authenticationService.login(code, fromPath)
            .then(
                user => {
                    if (fromPath != null) {
                        document.getElementById('status').innerText = "You're logged in. Redirecting to " + fromPath;
                        history.push(fromPath);
                    } else {
                        document.getElementById('status').innerText = "You're logged in";
                    }
                }
            ).catch(err => {
                document.getElementById('status').innerText = "An error occurred.\nIf this continues please contact your system administrator.";
            }
            );
    }

    render() {
        return (
            <div className="login">
                <h1 className="login-title">Login</h1>
                <h2 id="status" className="status">{authenticationService.currentUserValue?"You're logged in":"Authentication Required"}</h2>
                <div hidden={authenticationService.currentUserValue}>
                    <p>You must Login to view the content</p>
                    <a target="_self" href={authenticationService.getOauthLoginURL(queryStringHelper.getFromPath(null))}>Click here to Login</a>
                </div>
            </div>
        )
    }
}

export { LoginPage }; 