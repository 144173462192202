export const envVars = {
    getByCat_API_URL: getByCatAPIURL(),
    getByCtrl_API_URL: getByCtrlAPIURL(),
    auth_base_URL: getAuthBaseURL(),
    oAuth_Client_ID: getOAuthClientID()
};

function notNULLEmptyBlank(toTest){
    return toTest && /\S/.test(toTest);
}

function getByCatAPIURL(){
    if(notNULLEmptyBlank(process.env.REACT_APP_DATA_API_URL)){
        return process.env.REACT_APP_DATA_API_URL;
    }
    return window.location.origin + '/api/getbycat';
}

function getByCtrlAPIURL(){
    if(notNULLEmptyBlank(process.env.REACT_APP_DATA_API_GETBYCTRL_URL)){
        return process.env.REACT_APP_DATA_API_GETBYCTRL_URL;
    }
    return window.location.origin + '/api/getbyctrl';
}

function getAuthBaseURL(){
    if(notNULLEmptyBlank(process.env.REACT_APP_OAUTH2_BASE_URL)){
        return process.env.REACT_APP_OAUTH2_BASE_URL;
    }
    return window.location.origin+'/oauth2';
}

function getOAuthClientID(){
    if(notNULLEmptyBlank(process.env.REACT_APP_OAUTH2_CLIENT_ID)){
        return process.env.REACT_APP_OAUTH2_CLIENT_ID;
    }
    return '';
}