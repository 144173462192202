// TODO: Auto Logout
import { authenticationService } from '_services/authentication.service';
import { queryStringHelper } from '_helpers/query-string';

export function handleResponse(response) {
    return response.json().then(json => {
        const data = json;
        if (!response.ok) {
            // 400(Bad Request), 401(Unauthorized), 403(Forbidden)
            if ([401, 403].indexOf(response.status) !== -1) {
                // Auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                // authenticationService.logout();
                window.location.href = authenticationService.getOauthLoginURL(queryStringHelper.getFromPath(window.location.pathname));
            }
            // TODO: Add action for the following else if
            // else if ([400].indexOf(response.status) !== -1) {
                // Error 400 (URL String Syntax Error, Corrupted Browser Cache & Cookies, DNS Lookup Cache, File Size Too Large, Generic Server Error)
            // }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}