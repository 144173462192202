import React, { Component } from 'react';
import * as Survey from "survey-react";
import q from './Questionnaire.json'; //Questions
import './Questionnaire.css';
import { Redirect } from 'react-router-dom';

export default class Questionnaire extends Component{
	constructor(props){
		super(props);
		this.state = {
			// currentUser: authenticationService.currentUserValue
			sec6Complete: false,
			cdsOverlay:{
				CDSAccess: false,
				CDSTransfer: false,
				CDSMultilevel: false
			},
			cbxCF: false,
			nistCIAOverlay:{
				nistC: 'L',
				nistI: 'L',
				nistA: 'L'
			},
			cbxNC: false,
			cbxPHI: false,
			piiOverlay: 'L',
			cbxPII: false,
			sciOverlay:{
				intA: false,
				intB: false,
				intC: false
			},
			cbxSCI: false,
			cbxSP: false,
			sec4Complete: false,
			sec5Complete: false,
			sec5_2CriteriaMet: false,
			notiText: ''
		}
		Survey
			.StylesManager
			.applyTheme("bootstrap");

		// TODO Make it a one complete survey by using variables inside of the survey
		// Survey
		// 	.FunctionFactory
		// 	.Instance
		// 	.register("getSectionNum", getSectionNum);
		this.qOnChgd = this.qOnChgd.bind(this);
		this.qOnCmpl = this.qOnCmpl.bind(this);

		this.displayAns = this.displayAns.bind(this);
		this.whichInt = this.whichInt.bind(this);
		this.whichCDS = this.whichCDS.bind(this);
	}

	//Runs when options are changed (including first select)
	qOnChgd(survey) {
		//Default
		var	cdsOverlay={
				CDSAccess:false,
				CDSTransfer:false,
				CDSMultilevel:false
			},
			cbxCF=false,
			nistC='L',
			nistI='L',
			nistA='L',
			cbxPHI=false,
			piiOverlay='L',
			cbxPII=false,
			sciOverlay={
				intA:false,
				intB:false,
				intC:false
			},
			cbxSCI=false,
			cbxSP=false,
			notiText='';
		//Load survey answers
		var sData = survey.data;

		//Section 4 logic
		if(sData['sec4_1_1'] || sData['sec4_1_2']
		|| sData['sec4_1_3'] || sData['sec4_1_4']
		|| sData['sec4_1_5'] || sData['sec4_1_6']){
			nistC='M';
			nistI='M';
			nistA='M';
		}
		else if(sData['sec4_2_1'] || sData['sec4_2_2']
		|| sData['sec4_2_3'] || sData['sec4_2_4']
		|| sData['sec4_2_5'] || sData['sec4_2_6']
		|| sData['sec4_2_7']){
			nistC='M';
			nistI='M';
		}
		else if(sData['sec4_3_1'] || sData['sec4_3_2']){
			nistC='M';
		}
		else if(sData['sec4_4_1']){
			nistI='M';
			nistA='M';
		}
		else if(sData['sec4_5_1'] || sData['sec4_5_2']
		|| sData['sec4_5_3'] || sData['sec4_5_4']){
			nistI='M';
		}
		else if(sData['sec4_6_1'] || sData['sec4_6_2']
		|| sData['sec4_6_3'] || sData['sec4_6_4']
		|| sData['sec4_6_4']){
			nistC='L';
			nistI='L';
			nistA='L';
		}

		//Section 5 logic
		if(sData['sec5_1_0']){
			nistC='H';
		}
		if((nistI==='L')
		&& (nistC==='H')){
			nistI='M';
			notiText='The Integrity impact level has been adjusted to Moderate from Low because a Confidentiality impact of High requires a minimum Integrity impact of Moderate.';
		}
		//For exception, impact level may be lowered if specific requirement is met
		if(sData['sec5_3_0']){
			nistA='M';
		}
		//For exception, impact level may be lowered if specific requirement is met
		if(sData['sec5_4_0']){
			nistA='L';
		}

		//Section 6 logic
		var q2 = sData['sec6_1_2'];
		if(q2 && sData['sec6_1_1']) {
			sciOverlay[q2]=true;
			cbxSCI=true;
		}
		q2 = sData['sec6_2_4'];
		if(q2 && sData['sec6_2_1']) {
			cdsOverlay[q2]=true;
		}
		if(sData['sec6_3_1'] && sData['sec6_3_2']) {
			cbxSP=true;
		}
		if(sData['sec6_4_1'] && sData['sec6_4_2']) {
			cbxCF=true;
		}
		if(sData['sec6_5_1'] && sData['sec6_5_2']) {
			cbxPII=true;
			piiOverlay=sData['sec6_5_2'];
			if(sData['sec6_5_2']==='H') {
				cbxPHI=true;
			}
		}

		this.setState(prevState => ({
			cdsOverlay:{
				...prevState.cdsOverlay,
				CDSAccess: cdsOverlay.CDSAccess,
				CDSTransfer: cdsOverlay.CDSTransfer,
				CDSMultilevel: cdsOverlay.CDSMultilevel
			},
			cbxCF: cbxCF,
			nistCIAOverlay: {
				...prevState.nistCIAOverlay,
				nistC: nistC,
				nistI: nistI,
				nistA: nistA
			},
			cbxPHI: cbxPHI,
			piiOverlay: piiOverlay,
			cbxPII: cbxPII,
			sciOverlay:{
				intA: sciOverlay.intA,
				intB: sciOverlay.intB,
				intC: sciOverlay.intC
			},
			cbxSCI: cbxSCI,
			cbxSP: cbxSP,
			notiText: notiText
		}));
	}

	//Runs when survey is submitted
	qOnCmpl(survey) {
		this.qOnChgd(survey);
		this.setState({
			//Nist CIA Checkbox is checked when survey is submitted
			cbxNC: true,
			svComplete: true
		});
	}

	//Display logic indicates N/A when option is either no or not selected
	displayAns(overlay, controller=true) {
		var ans = "N/A";
		if(controller && overlay){
			if(overlay==='L'||overlay==='M'||overlay==='H') {
				ans = overlay;
			}
			ans = "Yes";
		}
		return ans;
	}

	//Display logic for int overlay
	whichInt() {
		var st = this.state.sciOverlay;
		var sci = "N/A";
		if(st.intC) {
			sci = "IntC";
		}
		else if(st.intB) {
			sci = "IntB";
		}
		else if(st.intA) {
			sci = "IntA";
		}
		return sci;
	}
	
	//Display logic for cds overlay
	whichCDS() {
		var st = this.state.cdsOverlay;
		var cds = "N/A";
		if(st.CDSAccess) {
			cds = "Access";
		}
		else if(st.CDSTransfer) {
			cds = "Transfer";
		}
		else if(st.CDSMultilevel) {
			cds = "Multilevel";
		}
		return cds;
	}

	render(){
		const {
			cbxCF,
			nistCIAOverlay,
			cbxNC,
			cbxPHI,
			piiOverlay,
			cbxPII,
			cbxSCI,
			sciOverlay,
			cbxSP,
			svComplete,
			notiText
		} = this.state;
		return(
			<div className="q-div">
				<div className="q-content">
					<div className="sec-title-div">
						<label className="sec-title">
							Questionnaire for selection assistance
						</label>
					</div>
					<div className="noti">
						<label className="noti-label">
							{notiText}
						</label>
					</div>
					{
						svComplete &&
						<Redirect
							to={{
								pathname: '/authed/SecurityControls',
								state: {
									cbxCF,
									nistCIAOverlay,
									cbxNC,
									cbxPHI,
									piiOverlay,
									cbxPII,
									cbxSCI,
									sciOverlay,
									cbxSP
								}
							}}
						/>
					}
					{
						!svComplete &&
						<div>
							<Survey.Survey
								className="survey-q"
								json={ q }
								onValueChanged={ this.qOnChgd }
								onComplete={ this.qOnCmpl }
							/>
						</div>
					}
				</div>
				<div className="q-choice-prev">
					<span className="prev-ttl">Adjusted Impact Levels</span><br/>
					<span className="prev-bdy">Confidentiality&nbsp;=&nbsp;{nistCIAOverlay.nistC}</span><br/>
					<span className="prev-bdy">Integrity&nbsp;=&nbsp;{nistCIAOverlay.nistI}</span><br/>
					<span className="prev-bdy">Availability&nbsp;=&nbsp;{nistCIAOverlay.nistA}</span><br/><br/>

					<span className="prev-ttl">Overlays</span><br/>
					<span className="prev-bdy">Intelligence&nbsp;=&nbsp;{this.whichInt()}</span><br/>
					<span className="prev-bdy">Space&nbsp;=&nbsp;{this.displayAns(cbxSP)}</span><br/>
					<span className="prev-bdy">Classified&nbsp;=&nbsp;{this.displayAns(cbxCF)}</span><br/>
					<span className="prev-bdy">PII&nbsp;=&nbsp;{this.displayAns(piiOverlay,cbxPII)}</span><br/>
					<span className="prev-bdy">PHI&nbsp;=&nbsp;{this.displayAns(cbxPHI)}</span><br/><br/>

					<span className="prev-ttl">Overlays (Unsupported)</span><br/>
					<span className="prev-bdy">CDS&nbsp;=&nbsp;{this.whichCDS()}</span><br/>
				</div>
			</div>
		)
	}
}