import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import Logo from 'assets/invictusLogo400x400.png';
import {LoginComponent} from 'LoginComponent/LoginComponent';
import 'components/Header.css';

export default class Header extends Component{
	render(){
		return(
			<div>
				<React.Fragment>
					<div className="header">
						<div className="title-box">
							<div className="loginOutBtn visibility-hidden"><LoginComponent/></div>
							<a href="/"><img className="inv-logo" src={Logo} alt="Invictus Logo"/></a>
							<span className="main-title">Secur-IT-Baseliner</span>
							<div className="loginOutBtn"><LoginComponent/></div>
						</div>
						<nav className="navlink">
							<ul>
								<li><NavLink exact to="/" activeClassName="active">Home</NavLink></li>
								<li><NavLink to="/authed/Questionnaire" activeClassName="active">Questionnaire</NavLink></li>
								<li><NavLink to="/authed/SecurityControls" activeClassName="active">Security Controls</NavLink></li>
								
							</ul>
						</nav>
					</div>
				</React.Fragment>
			</div>
		)
	}
}