import { authenticationService } from '_services/authentication.service';

export function authHeader() {
    return addAuthHeader({});
}

//Shallow copy add function will modify passed in object.  Assumes at least {}
export function addAuthHeader(curHeaders) {
    // return authorization header with jwt token
    const currentUser = authenticationService.currentUserValue;
    if (currentUser && currentUser.access_token) {
       //Note: Bearer doesn't process in AWS API - curHeaders["Authorization"] =  `Bearer ${currentUser.access_token}`;
        curHeaders["authorization"] =  `${currentUser.access_token}`;
    }
    return curHeaders;
}

//Shallow copy add function will modify passed in object.  Assumes at least {}
export function addCORS(curHeaders) {
    //TODO: evaluate necessity
    //const currentUser = authenticationService.currentUserValue;
    //if (currentUser && currentUser.token) {
    //   curHeaders["origin"] = "http://localhost:3000";
    //}
    return curHeaders;
}


//Shallow copy add will modify passed in object.  Assumes at least {}
export function addAuthCORS(curHeaders) {
    return addAuthHeader(addCORS(curHeaders));
}