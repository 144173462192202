import { BehaviorSubject } from 'rxjs';
import { handleResponse } from '_helpers/handle-response';
import { envVars } from '_helpers/env-vars';
import jwt from 'jsonwebtoken';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authenticationService = {
    login,
    refreshTokens,
    removeLocalUser,
    getOauthLoginURL,
    getOauthLogoutURL,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() { return currentUserSubject.value }
};

// Values to be set in applicaiton properties
// Currently does not support secret ID.
const oauthBaseURL = envVars.auth_base_URL;
const oauthCodeAuthPath = '/login';
const oauthCodeLogoutPath = '/logout';
const oauthTokenPath = '/oauth2/token';
const oauthClientID = envVars.oAuth_Client_ID;
const oauthScope = 'aws.cognito.signin.user.admin+email+openid';
const validFromPaths = ['/', '/login', '/authed/SecurityControls', '/authed/Questionnaire'];

function buildStateStr(fromPath){
    if (typeof fromPath !== 'undefined' && fromPath != null && validFromPaths.includes(fromPath)) {
        return fromPath;
    }
    return validFromPaths[0];
}

function getOauthLoginURL(fromPath) {
    return oauthBaseURL + oauthCodeAuthPath + '?response_type=code&client_id=' + oauthClientID
        + '&scope=' + oauthScope + '&redirect_uri=' + window.location.origin + '/login&state=' + encodeURIComponent(buildStateStr(fromPath));
}

function login(code,fromPath) {
    const qUrl = oauthBaseURL+oauthTokenPath;
    const settings = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: "grant_type=authorization_code&client_id="+oauthClientID+ "&redirect_uri=" +window.location.origin + '/login&code='+ code +'&state=' + buildStateStr(fromPath),
    };
    return fetch(qUrl, settings)
        .then(handleResponse)
        .then(setUserData); //Passing user (Javascript arrow notation)
}

function setUserDataRefresh(user){
    user['refresh_token'] = authenticationService.currentUserValue['refresh_token'];
    return setUserData(user);
}

function setUserData(user){
    user['access_token_exp'] = getExpDate(user['access_token']);
    // Store user details and jwt token in local storage to keep user logged in between page refreshes
    localStorage.setItem('currentUser', JSON.stringify(user));
    currentUserSubject.next(user);
    return user;
}

function getOauthLogoutURL() {
    return oauthBaseURL + oauthCodeLogoutPath + '?client_id=' + oauthClientID + '&logout_uri=' + window.location.origin + '/logout'; 
}

function removeLocalUser() {
    // Remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
}

function getExpDate(token) {
    if ((typeof token) === 'undefined' || token == null){
        return null;
    }
    var rtnExp= new Date();
    try{
        // Need signature file and info. consideration needs to be taken for how to handle long term maintenance.
        // var decoded = jwt.verify(token,oauthClientID);
        var decoded = jwt.decode(token);
        if(decoded !== null){
            rtnExp = new Date(1000*decoded.exp);
        }
    }
    catch(error){
        console.error(error);
    }
    return rtnExp;
}

function refreshTokens(force = false) {
    const usrV = authenticationService.currentUserValue
    let doRefresh = false;
    if (!force) {
        // Making sure the comparison is both in milliseconds"
        const accessTokenExp = new Date(usrV["access_token_exp"]);
        if(accessTokenExp !== null && (Date.now() + 500) > accessTokenExp.getTime()){
            doRefresh = true;
        }
    }
    if (doRefresh) {
        const qUrl = oauthBaseURL + oauthTokenPath;
        const refreshToken = usrV["refresh_token"];
        if(refreshToken == null ){
            return Promise.reject("Refresh token unavailable");
        }
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: "grant_type=refresh_token&client_id=" + oauthClientID + "&refresh_token=" + refreshToken,
        };
        return fetch(qUrl, settings)
                .then(handleResponse)
                .then(setUserDataRefresh);
    }
    else {
        return Promise.resolve(true);
    }
}