import React from 'react'
import Button from 'react-bootstrap/Button';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export const ExportCSV = ({csvData, fileName}) => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const headerMapping = {
        "ControlID": "Control ID",
        "Title": "Title",
        "Description": "Stated Requirements",
        "Public Implementation": null,
        "Notes": null,
        "Status": null,
        "Expected Completion": null,
        "Class": null,
        "Priority": null,
        "Responsible Entities": null,
        "Control Owner(s)": null,
        "Type": null,
        "Inherited From": null,
        "Provide As": null,
        "Evaluation Status": null,
        "Control Origination": null,
        "History": null
    };

    function mappedRecordRow(record) {
        let nRec = {};
        for ( const [k, v] of Object.entries(headerMapping)){
            if (typeof v === 'undefined' || v === null ){
                nRec[k] = "";
            }else{
                nRec[v] = record[k];
            }
        }
        return nRec;
    }

    var newJson = csvData.map(mappedRecordRow);

    const exportToCSV = (newJson, fileName) => {
        const ws = XLSX.utils.json_to_sheet(newJson);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        
        FileSaver.saveAs(data, fileName + fileExtension);
    }
    
    return (
        <Button
            className="btns"
            variant="warning"
            onClick={(e) => exportToCSV(newJson,fileName)}
        >
            Export
        </Button>
    )
}