import React, { Component } from 'react';
import {
    Router,
    Route,
    Switch
} from 'react-router-dom';
import Header from 'components/Header';
import Home from 'components/Home';
import Questionnaire from 'authed/questionnaire/Questionnaire';
import { SecurityControls } from 'authed/SecurityControls';
import { LoginPage } from 'LoginPage/LoginPage';
import { LogoutPage } from 'LogoutPage/LogoutPage';
import { history } from '_helpers/history';
import { authenticationService } from '_services/authentication.service';
import { PrivateRoute } from '_components/PrivateRoute';
import plume from 'assets/plume-white-on-trans-01.png';
import 'App.css';

export default class App extends Component{
    constructor(props) {
        super(props);
        this.state = {
            currentUser: null
        };
    }
    componentDidMount() {
        authenticationService.currentUser.subscribe(x => this.setState({ currentUser: x }));
    }
    logout() {
        authenticationService.logout();
        history.push('/login');
    }
    render(){
        return(
            <Router history={history}>
                <div className="app">
                    <Header />
                    <img className="plume" src={plume} alt="Invictus Plume"/>
                    <Switch>
                        <PrivateRoute path="/authed/SecurityControls" component={SecurityControls} />
                        <PrivateRoute path="/authed/Questionnaire" component={Questionnaire} />
                        <Route exact path="/login" component={LoginPage} />
                        <Route exact path="/logout" component={LogoutPage} />
                        {/* <Route exact path="/" component={Home} /> */}
                        <Route exact path="/*" component={Home} /> {/*TODO: better redirect needed such as https://reacttraining.com/react-router/web/example/auth-workflow*/}
                    </Switch>
                </div>
            </Router>
        );
    }
}