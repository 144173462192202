import React, { Component, Fragment } from 'react';
import ReactTable from 'react-table-6';
import Button from 'react-bootstrap/Button';
import { ExportCSV } from 'authed/ExportCSV';
import { authenticationService } from '_services/authentication.service';
import { addAuthCORS } from '_helpers/auth-header';
import { envVars } from '_helpers/env-vars';
import 'react-table-6/react-table.css';
import 'authed/SecurityControls.scss';

import Modal from 'react-modal';

class SecurityControls extends Component{
	constructor(props){
		super(props);
		// States of the SC component
		// When updating, should use "setState" command
		this.state = {
			showModal: false,
			rtnCtrl: [],
			ctrl: [],
			count: 0,
            currentUser: authenticationService.currentUserValue,
			fileName: 'controls',
			posts: [],
			message: '',
			cclOverlay:{
				CCLJ: false,
				CCLIF: false
			},
			cbxCCL: false,
			cbxCF: false,
			devOpsSecOverlay:{
				MVP: false,
				Std: false,
				ProposedMVP: false,
				ProposedStd: false
			},
			cbxDevOpsSec: false,
			nistCIAOverlay:{
				nistC: 'L',
				nistI: 'L',
				nistA: 'L'
			},
			cbxNC: false,
			cbxPHI: false,
			piiOverlay: 'L',
			cbxPII: false,
			sciOverlay:{
				intA: false,
				intB: false,
				intC: false
			},
			cbxSCI: false,
			cbxSP: false,
			srgSCCAOverlay:{
				srgCAP: false,
				srgBCAP: false,
				srgVDSS: false,
				srgVDMS: false
			},
			cbxSRGSCCA: false,
			srgModOverlay:{
				IL2: false,
				IL4: false,
				IL5: false,
				IL6: false
			},
			cbxSRGMod: false,
			srgHighOverlay:{
				IL4: false,
				IL5: false,
				IL6: false
			},
			cbxSRGHigh: false
		};
		this.handleCCL = this.handleCCL.bind(this);
		this.toggleCCL = this.toggleCCL.bind(this);
		this.handleCF = this.handleCF.bind(this);
		this.handleDevOpsSec = this.handleDevOpsSec.bind(this);
		this.toggleDevOpsSec = this.toggleDevOpsSec.bind(this);
		this.handleNC = this.handleNC.bind(this);
		this.toggleNC = this.toggleNC.bind(this);
		this.handlePHI = this.handlePHI.bind(this);
		this.handlePII = this.handlePII.bind(this);
		this.togglePII = this.togglePII.bind(this);
		this.handleSCI = this.handleSCI.bind(this);
		this.toggleSCI = this.toggleSCI.bind(this);
		this.handleSP = this.handleSP.bind(this);
		this.handleSRGSCCA = this.handleSRGSCCA.bind(this);
		this.toggleSRGSCCA = this.toggleSRGSCCA.bind(this);
		this.handleSRGMod = this.handleSRGMod.bind(this);
		this.toggleSRGMod = this.toggleSRGMod.bind(this);
		this.handleSRGHigh = this.handleSRGHigh.bind(this);
		this.toggleSRGHigh = this.toggleSRGHigh.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.descOnClick = this.descOnClick.bind(this);
		this.renderByCtrl = this.renderByCtrl.bind(this);
	}
	componentDidMount(){
		const msg = this.props.location.state;
		if(msg){
			for (const ans in msg) {
				this.setState({[ans]: msg[ans]});
			}
		}
		Modal.setAppElement('#ctrl');
	}
	handleCCL(event){
		event.persist();
		if (event.target.name === 'CCLJ') {
			this.setState(prevState => ({
				cclOverlay: {
					...prevState.cclOverlay,
					CCLJ: !this.state.cclOverlay.CCLJ
				}
			}));
		} else {
			this.setState(prevState => ({
				cclOverlay: {
					...prevState.cclOverlay,
					CCLIF: !this.state.cclOverlay.CCLIF
				}
			}));
		}
	}
	toggleCCL(event){
		event.persist();
		this.setState(prevState => ({
			cbxCCL: !prevState.cbxCCL
		}));
		if(event.target.checked){
			this.setState(prevState => ({
				cclOverlay: {
					...prevState.cclOverlay,
					CCLJ: true,
					CCLIF: false
				}
			}));
		}
		else{
			this.setState(prevState => ({
				cclOverlay: {
					...prevState.cclOverlay,
					CCLJ: false,
					CCLIF: false
				}
			}));
		}
	}
	handleCF(event){
		event.persist();
		this.setState({
			cbxCF: event.target.checked
		});
	}
	handleDevOpsSec(event){
		event.persist();
		if (event.target.name === 'MVP') {
			this.setState(prevState => ({
				devOpsSecOverlay: {
					...prevState.devOpsSecOverlay,
					MVP: !this.state.devOpsSecOverlay.MVP
				}
			}));
		} else if (event.target.name === 'Std') {
			this.setState(prevState => ({
				devOpsSecOverlay: {
					...prevState.devOpsSecOverlay,
					Std: !this.state.devOpsSecOverlay.Std
				}
			}));
		} else if (event.target.name === 'ProposedMVP') {
			this.setState(prevState => ({
				devOpsSecOverlay: {
					...prevState.devOpsSecOverlay,
					ProposedMVP: !this.state.devOpsSecOverlay.ProposedMVP
				}
			}));
		} else {
			this.setState(prevState => ({
				devOpsSecOverlay: {
					...prevState.devOpsSecOverlay,
					ProposedStd: !this.state.devOpsSecOverlay.ProposedStd
				}
			}));
		}
	}
	toggleDevOpsSec(event){
		event.persist();
		this.setState(prevState => ({
			cbxDevOpsSec: !prevState.cbxDevOpsSec
		}));
		if(event.target.checked){
			this.setState(prevState => ({
				devOpsSecOverlay: {
					...prevState.devOpsSecOverlay,
					MVP: true,
					Std: false,
					ProposedMVP: false,
					ProposedStd: false
				}
			}));
		}
		else{
			this.setState(prevState => ({
				devOpsSecOverlay: {
					...prevState.devOpsSecOverlay,
					MVP: false,
					Std: false,
					ProposedMVP: false,
					ProposedStd: false
				}
			}));
		}
	}
	handleNC(event){
		event.persist();
		this.setState(prevState => ({
			nistCIAOverlay: {
				...prevState.nistCIAOverlay,
				[event.target.name]: event.target.value
			}
		}));
	}
	toggleNC(event){
		event.persist();
		this.setState(prevState => ({
			cbxNC: !prevState.cbxNC
		}));
		if(event.target.checked){
			this.setState(prevState => ({
				nistCIAOverlay: {
					...prevState.nistCIAOverlay,
					nistC: 'L',
					nistI: 'L',
					nistA: 'L'
				}
			}));
		}
		else{
			this.setState(prevState => ({
				nistCIAOverlay: {
					...prevState.nistCIAOverlay,
					nistC: 'L',
					nistI: 'L',
					nistA: 'L'
				}
			}));
		}
	}
	handlePHI(event){
		event.persist();
		this.setState({
			cbxPHI: event.target.checked
		});
	}
	handlePII(event){
		event.persist();
		this.setState({
			piiOverlay: event.target.value			
		});
	}
	togglePII(event){
		event.persist();
		this.setState(prevState => ({
			cbxPII: !prevState.cbxPII
		}));
		if(event.target.checked){
			this.setState(prevState => ({
				piiOverlay: prevState.piiOverlay='L'
			}));
		}
		else{
			this.setState(prevState => ({
				piiOverlay: prevState.piiOverlay='L'
			}));
		}
	}
	handleSCI(event){
		event.persist();
		if (event.target.name === 'intA') {
			this.setState(prevState => ({
				sciOverlay: {
					...prevState.sciOverlay,
					intA: true,
					intB: false,
					intC: false
				}
			}));
		} else if (event.target.name === 'intB') {
			this.setState(prevState => ({
				sciOverlay: {
					...prevState.sciOverlay,
					intA: false,
					intB: true,
					intC: false
				}
			}));
		} else {
			this.setState(prevState => ({
				sciOverlay: {
					...prevState.sciOverlay,
					intA: false,
					intB: false,
					intC: true
				}
			}));
		}
	}
	toggleSCI(event){
		event.persist();
		this.setState(prevState => ({
			cbxSCI: !prevState.cbxSCI
		}));
		if(event.target.checked){
			this.setState(prevState => ({
				sciOverlay: {
					...prevState.sciOverlay,
					intA: true,
					intB: false,
					intC: false
				}
			}));
		}
		else{
			this.setState(prevState => ({
				sciOverlay: {
					...prevState.sciOverlay,
					intA: false,
					intB: false,
					intC: false
				}
			}));
		}
	}
	handleSP(event){
		event.persist();
		this.setState({
			cbxSP: event.target.checked
		});
	}
	handleSRGSCCA(event){
		event.persist();
		if (event.target.name === 'CAP') {
			this.setState(prevState => ({
				srgSCCAOverlay: {
					...prevState.srgSCCAOverlay,
					srgCAP: !this.state.srgSCCAOverlay.srgCAP
				}
			}));
		} else if (event.target.name === 'BCAP') {
			this.setState(prevState => ({
				srgSCCAOverlay: {
					...prevState.srgSCCAOverlay,
					srgBCAP: !this.state.srgSCCAOverlay.srgBCAP
				}
			}));
		} else if (event.target.name === 'VDSS') {
			this.setState(prevState => ({
				srgSCCAOverlay: {
					...prevState.srgSCCAOverlay,
					srgVDSS: !this.state.srgSCCAOverlay.srgVDSS
				}
			}));
		} else {
			this.setState(prevState => ({
				srgSCCAOverlay: {
					...prevState.srgSCCAOverlay,
					srgVDMS: !this.state.srgSCCAOverlay.srgVDMS
				}
			}));
		}
	}
	toggleSRGSCCA(event){
		event.persist();
		this.setState(prevState => ({
			cbxSRGSCCA: !prevState.cbxSRGSCCA
		}));
		if(event.target.checked){
			this.setState(prevState => ({
				srgSCCAOverlay: {
					...prevState.srgSCCAOverlay,
					srgCAP: true,
					srgBCAP: false,
					srgVDSS: false,
					srgVDMS: false
				}
			}));
		}
		else{
			this.setState(prevState => ({
				srgSCCAOverlay: {
					...prevState.srgSCCAOverlay,
					srgCAP: false,
					srgBCAP: false,
					srgVDSS: false,
					srgVDMS: false
				}
			}));
		}
	}
	handleSRGMod(event){
		event.persist();
		if (event.target.name === 'IL2') {
			this.setState(prevState => ({
				srgModOverlay: {
					...prevState.srgModOverlay,
					IL2: !this.state.srgModOverlay.IL2
				}
			}));
		} else if (event.target.name === 'IL4') {
			this.setState(prevState => ({
				srgModOverlay: {
					...prevState.srgModOverlay,
					IL4: !this.state.srgModOverlay.IL4
				}
			}));
		} else if (event.target.name === 'IL5') {
			this.setState(prevState => ({
				srgModOverlay: {
					...prevState.srgModOverlay,
					IL5: !this.state.srgModOverlay.IL5
				}
			}));
		} else {
			this.setState(prevState => ({
				srgModOverlay: {
					...prevState.srgModOverlay,
					IL6: !this.state.srgModOverlay.IL6
				}
			}));
		}
	}
	toggleSRGMod(event){
		event.persist();
		this.setState(prevState => ({
			cbxSRGMod: !prevState.cbxSRGMod
		}));
		if(event.target.checked){
			this.setState(prevState => ({
				srgModOverlay: {
					...prevState.srgModOverlay,
					IL2: true,
					IL4: false,
					IL5: false,
					IL6: false
				}
			}));
		}
		else{
			this.setState(prevState => ({
				srgModOverlay: {
					...prevState.srgModOverlay,
					IL2: false,
					IL4: false,
					IL5: false,
					IL6: false
				}
			}));
		}
	}
	handleSRGHigh(event){
		event.persist();
		if (event.target.name === 'IL4') {
			this.setState(prevState => ({
				srgHighOverlay: {
					...prevState.srgHighOverlay,
					IL4: !this.state.srgHighOverlay.IL4
				}
			}));
		} else if (event.target.name === 'IL5') {
			this.setState(prevState => ({
				srgHighOverlay: {
					...prevState.srgHighOverlay,
					IL5: !this.state.srgHighOverlay.IL5
				}
			}));
		} else {
			this.setState(prevState => ({
				srgHighOverlay: {
					...prevState.srgHighOverlay,
					IL6: !this.state.srgHighOverlay.IL6
				}
			}));
		}
	}
	toggleSRGHigh(event){
		event.persist();
		this.setState(prevState => ({
			cbxSRGHigh: !prevState.cbxSRGHigh
		}));
		if(event.target.checked){
			this.setState(prevState => ({
				srgHighOverlay: {
					...prevState.srgHighOverlay,
					IL4: true,
					IL5: false,
					IL6: false
				}
			}));
		}
		else{
			this.setState(prevState => ({
				srgHighOverlay: {
					...prevState.srgHighOverlay,
					IL4: false,
					IL5: false,
					IL6: false
				}
			}));
		}
	}
	handleClear(event){
		event.preventDefault();
		this.setState(prevState => ({
			cclOverlay:{
				...prevState.cclOverlay,
				CCLJ: false,
				CCLIF: false
			},
			cbxCCL: false,
			cbxCF: false,
			devOpsSecOverlay:{
				MVP: false,
				Std: false,
				ProposedMVP: false,
				ProposedStd: false
			},
			cbxDevOpsSec: false,
			nistCIAOverlay: {
				...prevState.nistCIAOverlay,
				nistC: 'L',
				nistI: 'L',
				nistA: 'L'
			},
			cbxNC: false,
			cbxPHI: false,
			piiOverlay: 'L',
			cbxPII: false,
			sciOverlay: {
				...prevState.sciOverlay,
				intA: false,
				intB: false,
				intC: false
			},
			cbxSCI: false,
			cbxSP: false,
			srgSCCAOverlay:{
				...prevState.srgSCCAOverlay,
				srgCAP: false,
				srgBCAP: false,
				srgVDSS: false,
				srgVDMS: false
			},
			cbxSRGSCCA: false,
			srgModOverlay:{
				...prevState.srgModOverlay,
				IL2: false,
				IL4: false,
				IL5: false,
				IL6: false
			},
			cbxSRGMod: false,
			srgHighOverlay:{
				...prevState.srgHighOverlay,
				IL4: false,
				IL5: false,
				IL6: false
			},
			cbxSRGHigh: false
		}));
	}
	handleSubmit(event) {
		event.preventDefault();
		this.setState({ message: "Querying..." });
		let Qstr = "?";
		if (this.state.cbxCCL) {
			if (this.state.cclOverlay.CCLJ) Qstr += 'cclJ=true&';
			if (this.state.cclOverlay.CCLIF) Qstr += 'cclIF=true&';
		}
		if (this.state.cbxCF) Qstr += 'cf=true&';
		if (this.state.cbxDevOpsSec) {
			if (this.state.devOpsSecOverlay.MVP) Qstr += 'MVP=true&';
			if (this.state.devOpsSecOverlay.Std) Qstr += 'Std=true&';
			if (this.state.devOpsSecOverlay.ProposedMVP) Qstr += 'ProposedMVP=true&';
			if (this.state.devOpsSecOverlay.ProposedStd) Qstr += 'ProposedStd=true&';
		}
		if (this.state.cbxPHI) Qstr += 'phi=true&';
		if (this.state.cbxPII) Qstr += `pii=${this.state.piiOverlay}&`;
		if (this.state.cbxNC) Qstr += `conf=${this.state.nistCIAOverlay.nistC}&integ=${this.state.nistCIAOverlay.nistI}&avail=${this.state.nistCIAOverlay.nistA}&`;
		if (this.state.cbxSCI) {
			if (this.state.sciOverlay.intA) Qstr += 'intA=true&';
			if (this.state.sciOverlay.intB) Qstr += 'intB=true&';
			if (this.state.sciOverlay.intC) Qstr += 'intC=true&';
		}
		if (this.state.cbxSP) Qstr += 'sp=true&';
		if (this.state.cbxSRGSCCA) {
			if (this.state.srgSCCAOverlay.srgCAP) Qstr += 'srgCAP=true&';
			if (this.state.srgSCCAOverlay.srgBCAP) Qstr += 'srgBCAP=true&';
			if (this.state.srgSCCAOverlay.srgVDSS) Qstr += 'srgVDSS=true&';
			if (this.state.srgSCCAOverlay.srgVDMS) Qstr += 'srgVDMS=true&';
		}
		if (this.state.cbxSRGMod) {
			if (this.state.srgModOverlay.IL2) Qstr += 'srgModIL2=true&';
			if (this.state.srgModOverlay.IL4) Qstr += 'srgModIL4=true&';
			if (this.state.srgModOverlay.IL5) Qstr += 'srgModIL5=true&';
			if (this.state.srgModOverlay.IL6) Qstr += 'srgModIL6=true&';
		}
		if (this.state.cbxSRGHigh) {
			if (this.state.srgHighOverlay.IL4) Qstr += 'srgHighIL4=true&';
			if (this.state.srgHighOverlay.IL5) Qstr += 'srgHighIL5=true&';
			if (this.state.srgHighOverlay.IL6) Qstr += 'srgHighIL6=true&';
		}
		Qstr = Qstr.replace(/&+\s*$/, "");

		const url = envVars.getByCat_API_URL + Qstr;
		// TODO: validate this is minimum cors request setup necessary
		authenticationService.refreshTokens()
		.then(res=>
			fetch(url, {
				method: "GET",
				headers: addAuthCORS({ 'Content-Type': 'application/x-www-form-urlencoded' }),
				credentials: 'include',
				mode: 'cors'
			})
			.then(response => {
				// Response attributes: type, url, redirected, status, ok, statusText, headers, body, bodyUsed, clone, arrayBuffer, blob, formData, json, text
				if(!response.ok){
					if(response.status === 401){
						this.setState({ message: "Unable to authenticate.  Tokens may be stale please logout, login, and retry." });
					} else {
						this.setState({ message: "An error has occured. Possible resolutions:\n1.Retry 2.Logout, Login, and Retry 3.Contact Administrator" });
					}
					return [];
				}
				// Data Query success
				this.setState({ message: "" });
				return response.json();
			})
			.then(posts => {
				this.setState({ posts });
			})
		)
		.catch(err=>{
			console.log("API request failed", err);
			this.setState({ posts: [], message: "An error has occured. Possible resolutions:\n1.Retry 2.Logout, Login, and Retry 3.Contact Administrator" });
		});
	}
	toggleModal(){
		this.setState({
			showModal: !this.state.showModal
		});
	}
	descOnClick(dbPosts){
		this.setState({
			message: "Loading...",
			ctrl: []
		});
		const rtnCtrlArray = this.state.rtnCtrl.find(family => dbPosts.Family in family);
		let ctrl;

		if(rtnCtrlArray){
			const c1Exists = rtnCtrlArray[dbPosts.Family].c1.find(c1 => c1.id === dbPosts[`CTRL-N1`]);
			if(c1Exists){
				ctrl = this.state.ctrl;
				ctrl.c1 = [c1Exists];
			}
		}
		if(ctrl){
			this.setState({
				message: "",
				ctrl: ctrl
			});
		}
		else{
			let Qstr = "?";
			Qstr += 'fm='+dbPosts.Family+'&';
			Qstr += 'c1='+dbPosts[`CTRL-N1`]+'&';
			Qstr += 'format=H';
			const url = envVars.getByCtrl_API_URL + Qstr;
			// TODO: validate this is minimum cors request setup necessary
			authenticationService.refreshTokens()
			.then(res=>
				fetch(url, {
					method: "GET",
					headers: addAuthCORS({ 'Content-Type': 'application/x-www-form-urlencoded' }),
					credentials: 'include',
					mode: 'cors'
				})
				.then(response => {
					// Response attributes: type, url, redirected, status, ok, statusText, headers, body, bodyUsed, clone, arrayBuffer, blob, formData, json, text
					if(!response.ok){
						if(response.status === 401){
							this.setState({ message: "Unable to authenticate.  Tokens may be stale please logout, login, and retry." });
						} else {
							this.setState({ message: "An error has occured. Possible resolutions:\n1.Retry 2.Logout, Login, and Retry 3.Contact Administrator" });
						}
						return [];
					}
					// Data Query success
					this.setState({ message: "" });
					return response.json();
				})
				.then(rtnCtrl => {
					var rtnCtrlArr = this.state.rtnCtrl;
					const familyFound = rtnCtrlArr.find(family => dbPosts.Family in family);
					if(familyFound) {
						const c1Found = familyFound[dbPosts.Family].c1.find(c1 => c1.id === dbPosts[`CTRL-N1`]);
						if(!c1Found) {
							familyFound[dbPosts.Family].c1.push(rtnCtrl[dbPosts.Family].c1[0]);
						}
					}
					else {
						rtnCtrlArr.push(rtnCtrl);
					}
					this.setState({
						rtnCtrlArr,
						ctrl: rtnCtrl[dbPosts.Family]
					});
				})
			)
			.catch(err=>{
				console.log("API request failed", err);
				this.setState({ rtnCtrl: [], message: "An error has occured. Possible resolutions:\n1.Retry 2.Logout, Login, and Retry 3.Contact Administrator" });
			});
		}
		this.toggleModal();
	}
	renderByCtrl(){
		const ctrls = this.state.ctrl;
		const c1 = ctrls.c1;
		const family = ctrls.family;
		return (
			<Fragment>
				{
					// if exits
					c1 && c1.length>0 &&
					<div className="modal-data-div">
						<h3>FAMILY: {family}</h3>
						{c1.map(data => this.renderC1(data, family))}
					</div>
				}
			</Fragment>
		)
	}
	renderC1(c1, family){
		return (
			<Fragment key={c1}>
				<h3 className="modal-title"><span className="bullet">{c1.fullID}</span>{c1.title}</h3>
				<p className="control-group-title">Control: {c1.txt}</p>
				{
					// if exits
					c1.c2 && c1.c2.length>0 &&
					<Fragment>
						<ul>
							{c1.c2.map((c2, index) => {
								return <li key={index}>{this.renderC2(c2)}</li>
							})}
						</ul>
					</Fragment>
				}
				{
					// if exits
					c1.e1 && c1.e1.length>0 &&
					<Fragment>
						<p className="control-group-title">Control Enhancements:</p>
						<ul>
							{c1.e1.map((e1, index) => {
								return <li key={index}>{this.renderE1(e1, family)}</li>
							})}
						</ul>
					</Fragment>
				}
			</Fragment>
		)
	}
	renderC2(c2){
		return (
			<Fragment>
				<span className="bullet">{c2.id}.</span>
				{c2.txt}
				<ul>
					{
						// if exits
						c2.c3 && c2.c3.length>0 &&
						c2.c3.map((c3, index) => {
							return <li key={index}>{this.renderC3(c3)}</li>
						})
					}
				</ul>
			</Fragment>
		)
	}
	renderC3(c3){
		return (
			<Fragment>
				<span className="bullet">{c3.id}.</span>
				{c3.txt}
			</Fragment>
		)
	}
	renderE1(e1, family){
		return (
			<Fragment>
				<span className="bullet">({e1.id})</span>
				<span>{family}</span><span>|</span><span>{e1.title}</span>
				<p className="bullet-sub">{e1.txt}</p>
				<ul>
					{
						// if exits
						e1.e2 && e1.e2.length>0 &&
						e1.e2.map((e2, index) => {
							return <li key={index}>{this.renderE2(e2)}</li>
						})
					}
				</ul>
			</Fragment>
		)
	}
	renderE2(e2){
		return (
			<Fragment>
				<span className="bullet">({e2.id})</span>
				{e2.txt}
				<ul>
					{
						// if exits
						e2.e3 && e2.e3.length>0 &&
						e2.e3.map((e3, index) => {
							return <li key={index}>{this.renderE3(e3)}</li>
						})
					}
				</ul>
			</Fragment>
		)
	}
	renderE3(e3){
		return (
			<Fragment>
				<span className="bullet">({e3.id})</span>
				{e3.txt}
			</Fragment>
		)
	}
	render(){
		return(
			<div id='ctrl'>
				<div className="ctrl-chkbx">
					<div className="scheading">
						<h2 className="sctitle2">
							Select System Categorizations and Overlays
						</h2>
					</div>
					<form
						onSubmit={this.handleSubmit}>
						<div className="opt-main-div">
							<div className="opt">
								<div className="opt-title">
									<input
										value={this.state.cbxNC}
										checked={this.state.cbxNC}
										onChange={this.toggleNC}
										type="checkbox"
										id="cbx-01-title-id"/>
									<label htmlFor="cbx-01-title-id" title="NIST 800-53 r.4">NIST CIA</label>
								</div>
								<div className="grid-container">
									<div hidden={!this.state.cbxNC}>
										<div>
											<select
												className="opt-slct"
												name="nistC"
												onChange={this.handleNC}
												disabled={!this.state.cbxNC}
												value={this.state.nistCIAOverlay.nistC}
												id="cbx-01-sub-01-id"
											>
												<option value="L">Low</option>
												<option value="M">Mod</option>
												<option value="H">High</option>
											</select>
										</div>
										<label className="selc-lbl" title="Confidentiality">C</label>
									</div>
									<div hidden={!this.state.cbxNC}>
										<div>
											<select
												className="opt-slct"
												name="nistI"
												onChange={this.handleNC}
												disabled={!this.state.cbxNC}
												value={this.state.nistCIAOverlay.nistI}
												id="cbx-01-sub-02-id"
											>
												<option value="L">Low</option>
												<option value="M">Mod</option>
												<option value="H">High</option>
											</select>
										</div>
										<label className="selc-lbl" title="Integrity">I</label>
									</div>
									<div hidden={!this.state.cbxNC}>
										<div>
											<select
												className="opt-slct"
												name="nistA"
												onChange={this.handleNC}
												disabled={!this.state.cbxNC}
												value={this.state.nistCIAOverlay.nistA}
												id="cbx-01-sub-03-id"
											>
												<option value="L">Low</option>
												<option value="M">Mod</option>
												<option value="H">High</option>
											</select>
										</div>
										<label className="selc-lbl" title="Availability">A</label>
									</div>
								</div>
							</div>
							<div className="opt">
								<div className="opt-title">
									<input
										value={this.state.cbxCCL}
										checked={this.state.cbxCCL}
										onChange={this.toggleCCL}
										type="checkbox"
										id="cbx-ccl-id"/>
									<label htmlFor="cbx-ccl-id" title="Critical Controls List">CCL</label>
								</div>
								<div className="grid-container">
									<div hidden={!this.state.cbxCCL}>
										<input
											className="opt-cbx"
											name="CCLJ"
											checked={this.state.cclOverlay.CCLJ}
											onChange={this.handleCCL}
											disabled={!this.state.cbxCCL}
											type="checkbox"
											id="cbx-02-sub-01-id"/>
										<label className="cbx-lbl" htmlFor="cbx-02-sub-01-id" title="J"><br/>J</label>
									</div>
									<div hidden={!this.state.cbxCCL}>
										<input
											className="opt-cbx"
											name="CCLIF"
											checked={this.state.cclOverlay.CCLIF}
											onChange={this.handleCCL}
											disabled={!this.state.cbxCCL}
											type="checkbox"
											id="cbx-02-sub-02-id"/>
										<label className="cbx-lbl" htmlFor="cbx-02-sub-02-id" title="Internet Facing"><br/>IF</label>
									</div>
								</div>
							</div>
							<div className="opt">
								<div className="opt-title">
									<input
										value={this.state.cbxCF}
										checked={this.state.cbxCF}
										onChange={this.handleCF}
										type="Checkbox"
										id="cbx-cf-id"/>
									<label htmlFor="cbx-cf-id">Classified</label>
								</div>
							</div>
							<div className="opt">
								<div className="opt-title">
									<input
										className="opt-cbx"
										value={this.state.cbxSCI}
										checked={this.state.cbxSCI}
										onChange={this.toggleSCI}
										type="checkbox"
										id="cbx-04-title-id"/>
									<label htmlFor="cbx-04-title-id">Intelligence</label>
								</div>
								<div className="grid-container">
									<div hidden={!this.state.cbxSCI}>
										<input
											className="opt-cbx"
											name="intA"
											checked={this.state.sciOverlay.intA}
											onChange={this.handleSCI}
											disabled={!this.state.cbxSCI}
											type="checkbox"
											id="cbx-04-sub-01-id"/>
										<label htmlFor="cbx-04-sub-01-id"><br/>IntA</label>
									</div>
									<div hidden={!this.state.cbxSCI}>
										<input
											className="opt-cbx"
											name="intB"
											checked={this.state.sciOverlay.intB}
											onChange={this.handleSCI}
											disabled={!this.state.cbxSCI}
											type="checkbox"
											id="cbx-04-sub-02-id"/>
										<label htmlFor="cbx-04-sub-02-id"><br/>IntB</label>
									</div>
									<div hidden={!this.state.cbxSCI}>
										<input
											className="opt-cbx"
											name="intC"
											checked={this.state.sciOverlay.intC}
											onChange={this.handleSCI}
											disabled={!this.state.cbxSCI}
											type="checkbox"
											id="cbx-04-sub-03-id"/>
										<label htmlFor="cbx-04-sub-03-id"><br/>IntC</label>
									</div>
								</div>
							</div>
							<div className="opt">
								<div className="opt-title">
									<input
										value={this.state.cbxPII}
										checked={this.state.cbxPII}
										onChange={this.togglePII}
										type="Checkbox"
										id="cbx-05-title-id"/>
									<label htmlFor="cbx-05-title-id" title="Personally Identifiable Information">PII</label>
								</div>
								<div className="grid-container">
									<div hidden={!this.state.cbxPII}>
										<select
											className="opt-slct"
											name="pii"
											onChange={this.handlePII}
											disabled={!this.state.cbxPII}
											value={this.state.piiOverlay}
											id="cbx-05-sub-01-id"
										>
											<option value="L">Low</option>
											<option value="M">Mod</option>
											<option value="H">High</option>
										</select>
									</div>
								</div>
							</div>
							<div className="opt">
								<div className="opt-title">
									<input
										value={this.state.cbxPHI}
										checked={this.state.cbxPHI}
										onChange={this.handlePHI}
										type="Checkbox"
										id="cbx-06-title-id"/>
									<label htmlFor="cbx-06-title-id" title="Protected Health Information">PHI</label>
								</div>
							</div>
							<div className="opt">
								<div className="opt-title">
									<input
										value={this.state.cbxDevOpsSec}
										checked={this.state.cbxDevOpsSec}
										onChange={this.toggleDevOpsSec}
										type="checkbox"
										id="cbx-devOpsSec-id"/>
									<label htmlFor="cbx-devOpsSec-id" title="Software Development, IT Operations, Cybersecurity">DevOpsSec</label>
								</div>
								<div className="grid-container">
									<div hidden={!this.state.cbxDevOpsSec}>
										<input
											className="opt-cbx"
											name="MVP"
											checked={this.state.devOpsSecOverlay.MVP}
											onChange={this.handleDevOpsSec}
											disabled={!this.state.cbxDevOpsSec}
											type="checkbox"
											id="cbx-07-sub-01-id"/>
										<label htmlFor="cbx-07-sub-01-id" title="Minimum Viable Product"><br/>MVP</label>
									</div>
									<div hidden={!this.state.cbxDevOpsSec}>
										<input
											className="opt-cbx"
											name="Std"
											checked={this.state.devOpsSecOverlay.Std}
											onChange={this.handleDevOpsSec}
											disabled={!this.state.cbxDevOpsSec}
											type="checkbox"
											id="cbx-07-sub-02-id"/>
										<label htmlFor="cbx-07-sub-02-id" title="Standard"><br/>Std</label>
									</div>
									<div hidden={!this.state.cbxDevOpsSec}>
										<input
											className="opt-cbx"
											name="ProposedMVP"
											checked={this.state.devOpsSecOverlay.ProposedMVP}
											onChange={this.handleDevOpsSec}
											disabled={!this.state.cbxDevOpsSec}
											type="checkbox"
											id="cbx-07-sub-03-id"/>
										<label htmlFor="cbx-07-sub-03-id" title="Proposed Minimum Viable Product"><br/>PMVP</label>
									</div>
									<div hidden={!this.state.cbxDevOpsSec}>
										<input
											className="opt-cbx"
											name="ProposedStd"
											checked={this.state.devOpsSecOverlay.ProposedStd}
											onChange={this.handleDevOpsSec}
											disabled={!this.state.cbxDevOpsSec}
											type="checkbox"
											id="cbx-07-sub-04-id"/>
										<label htmlFor="cbx-07-sub-04-id" title="Proposed Standard"><br/>PStd</label>
									</div>
								</div>
							</div>
							<div className="opt">
								<div className="opt-title">
									<input
										value={this.state.cbxSP}
										checked={this.state.cbxSP}
										onChange={this.handleSP}
										type="Checkbox"
										id="cbx-sp-id"/>
									<label htmlFor="cbx-sp-id">Space</label>
								</div>
							</div>
							<div className="opt">
								<div className="opt-title">
									<input
										value={this.state.cbxSRGSCCA}
										checked={this.state.cbxSRGSCCA}
										onChange={this.toggleSRGSCCA}
										type="checkbox"
										id="cbx-srgSCCA-id"/>
									<label htmlFor="cbx-srgSCCA-id" title="Cloud Computing Security Requirements Guide - Secure Cloud Computing Architecture">SRG SCCA</label>
								</div>
								<div className="grid-container">
									<div hidden={!this.state.cbxSRGSCCA}>
										<input
											className="opt-cbx"
											name="CAP"
											checked={this.state.srgSCCAOverlay.srgCAP}
											onChange={this.handleSRGSCCA}
											disabled={!this.state.cbxSRGSCCA}
											type="checkbox"
											id="cbx-09-sub-01-id"/>
										<label htmlFor="cbx-09-sub-01-id" title="Cloud Access Point"><br/>CAP</label>
									</div>
									<div hidden={!this.state.cbxSRGSCCA}>
										<input
											className="opt-cbx"
											name="BCAP"
											checked={this.state.srgSCCAOverlay.srgBCAP}
											onChange={this.handleSRGSCCA}
											disabled={!this.state.cbxSRGSCCA}
											type="checkbox"
											id="cbx-09-sub-02-id"/>
										<label htmlFor="cbx-09-sub-02-id" title="Boundary Cloud Access Point"><br/>BCAP</label>
									</div>
									<div hidden={!this.state.cbxSRGSCCA}>
										<input
											className="opt-cbx"
											name="VDSS"
											checked={this.state.srgSCCAOverlay.srgVDSS}
											onChange={this.handleSRGSCCA}
											disabled={!this.state.cbxSRGSCCA}
											type="checkbox"
											id="cbx-09-sub-03-id"/>
										<label htmlFor="cbx-09-sub-03-id" title="Virtual Data Center Security Stack"><br/>VDSS</label>
									</div>
									<div hidden={!this.state.cbxSRGSCCA}>
										<input
											className="opt-cbx"
											name="VDMS"
											checked={this.state.srgSCCAOverlay.srgVDMS}
											onChange={this.handleSRGSCCA}
											disabled={!this.state.cbxSRGSCCA}
											type="checkbox"
											id="cbx-09-sub-04-id"/>
										<label htmlFor="cbx-09-sub-04-id" title="Virtual Data Center Managed Services"><br/>VDMS</label>
									</div>
								</div>
							</div>
							<div className="opt">
								<div className="opt-title">
									<input
										value={this.state.cbxSRGMod}
										checked={this.state.cbxSRGMod}
										onChange={this.toggleSRGMod}
										type="checkbox"
										id="cbx-srgMod-id"/>
									<label htmlFor="cbx-srgMod-id" title="Cloud Computing Security Requirements Guide - Moderate">SRG Moderate</label>
								</div>
								<div className="grid-container">
									<div hidden={!this.state.cbxSRGMod}>
										<input
											className="opt-cbx"
											name="IL2"
											checked={this.state.srgModOverlay.IL2}
											onChange={this.handleSRGMod}
											disabled={!this.state.cbxSRGMod}
											type="checkbox"
											id="cbx-10-sub-01-id"/>
										<label htmlFor="cbx-10-sub-01-id" title="Impact Level 2"><br/>IL2</label>
									</div>
									<div hidden={!this.state.cbxSRGMod}>
										<input
											className="opt-cbx"
											name="IL4"
											checked={this.state.srgModOverlay.IL4}
											onChange={this.handleSRGMod}
											disabled={!this.state.cbxSRGMod}
											type="checkbox"
											id="cbx-10-sub-02-id"/>
										<label htmlFor="cbx-10-sub-02-id" title="Impact Level 4"><br/>L4</label>
									</div>
									<div hidden={!this.state.cbxSRGMod}>
										<input
											className="opt-cbx"
											name="IL5"
											checked={this.state.srgModOverlay.IL5}
											onChange={this.handleSRGMod}
											disabled={!this.state.cbxSRGMod}
											type="checkbox"
											id="cbx-10-sub-03-id"/>
										<label htmlFor="cbx-10-sub-03-id" title="Impact Level 5"><br/>IL5</label>
									</div>
									<div hidden={!this.state.cbxSRGMod}>
										<input
											className="opt-cbx"
											name="IL6"
											checked={this.state.srgModOverlay.IL6}
											onChange={this.handleSRGMod}
											disabled={!this.state.cbxSRGMod}
											type="checkbox"
											id="cbx-10-sub-04-id"/>
										<label htmlFor="cbx-10-sub-04-id" title="Impact Level 6"><br/>IL6</label>
									</div>
								</div>
							</div>
							<div className="opt">
								<div className="opt-title">
									<input
										value={this.state.cbxSRGHigh}
										checked={this.state.cbxSRGHigh}
										onChange={this.toggleSRGHigh}
										type="checkbox"
										id="cbx-srgHigh-id"/>
									<label htmlFor="cbx-srgHigh-id" title="Cloud Computing Security Requirements Guide - High">SRG High</label>
								</div>
								<div className="grid-container">
									<div hidden={!this.state.cbxSRGHigh}>
										<input
											className="opt-cbx"
											name="IL4"
											checked={this.state.srgHighOverlay.IL4}
											onChange={this.handleSRGHigh}
											disabled={!this.state.cbxSRGHigh}
											type="checkbox"
											id="cbx-11-sub-01-id"/>
										<label htmlFor="cbx-11-sub-01-id" title="Impact Level 4"><br/>IL4</label>
									</div>
									<div hidden={!this.state.cbxSRGHigh}>
										<input
											className="opt-cbx"
											name="IL5"
											checked={this.state.srgHighOverlay.IL5}
											onChange={this.handleSRGHigh}
											disabled={!this.state.cbxSRGHigh}
											type="checkbox"
											id="cbx-11-sub-02-id"/>
										<label htmlFor="cbx-11-sub-02-id" title="Impact Level 5"><br/>IL5</label>
									</div>
									<div hidden={!this.state.cbxSRGHigh}>
										<input
											className="opt-cbx"
											name="IL6"
											checked={this.state.srgHighOverlay.IL6}
											onChange={this.handleSRGHigh}
											disabled={!this.state.cbxSRGHigh}
											type="checkbox"
											id="cbx-11-sub-03-id"/>
										<label htmlFor="cbx-11-sub-03-id" title="Impact Level 6"><br/>IL6</label>
									</div>
								</div>
							</div>
						</div>
						<div className="ctrls">
							<Button className="btns" onClick={this.handleClear}>
								Clear
							</Button>
							<ExportCSV csvData={this.state.posts} fileName={this.state.fileName}/>
							<input className="btns" type="submit" value="Submit"/>
						</div>
					</form>
				</div>
				{
					!this.state.showModal &&
					<div id="messaging" className="messaging">{this.state.message}</div>
				}
				<div className="master-table">
					<ReactTable
						// ReactTable Document URL
						// https://www.npmjs.com/package/react-table-6
						className="sc-data"
						data={this.state.posts}
						// filterable
						defaultPageSize={20}
						style={{overflow:'wrap'}}
						pagination
						showPaginationTop
						showPageSizeOptions
						pageSizeOptions={[1, 5, 10, 20, 25, 50, 100, 1000, 2000]}
						showPageJump
						columns = {[
						{
							Header: "Control ID",
							accessor: "ControlID",
							sortable: false,
							filterable: true,
							style: {
								textAlign: "left"
							},
							maxWidth: 140
						},
						{
							Header: "Title",
							accessor: "Title",
							sortable: true,
							filterable: true,
							style: {
								textAlign: "left",
								'whiteSpace': 'unset'
							},
							maxWidth: 500
						},
						{
							Header: "Stated Requirement",
							accessor: "Description",
							sortable: true,
							filterable: true,
							Cell     : props =>
							<div>
								<button className="q-mark" onClick={open => this.descOnClick(props.original)}>?</button>
								<span className="desc">{props.original.Description}</span>
							</div>,
							style: {
								textAlign: "left",
								'whiteSpace': 'unset'
							},
							maxWidth: 1020
						}
						]}
					/>
				</div>
				<Modal className="modal-by-ctrl" isOpen={this.state.showModal} contentLabel="Full Control Description Modal">
					<div id="messaging" className="messaging">{this.state.message}</div>
					<div className="modal-content">
						<span className="close-button" onClick={close => this.toggleModal()}/>
						<div className="rtn-ctrl-div">
							{this.renderByCtrl()}
						</div>
					</div>
				</Modal>
			</div>
		);
	}
}
export { SecurityControls };