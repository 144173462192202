import React from 'react';
import { queryStringHelper } from '_helpers/query-string';
import { authenticationService } from '_services/authentication.service';
import 'LoginComponent/LoginComponent.css';

class LoginComponent extends React.Component {

    constructor(props) {
        super(props);
        this.loginRedirect = this.loginRedirect.bind(this);
        this.logoutRedirect = this.logoutRedirect.bind(this);
    }

    logoutRedirect(e) {  
        e.preventDefault();
        window.location.href = authenticationService.getOauthLogoutURL();
    } 

    loginRedirect(e) {
        e.preventDefault();
        window.location.href = authenticationService.getOauthLoginURL(queryStringHelper.getFromPath(window.location.pathname));
    }

    render() {
        const currentUser = authenticationService.currentUserValue;
        let link = <a id="logout" onClick={this.logoutRedirect} href="/logout">Logout</a>;
        if (!currentUser) {
            link = <a id="login" onClick={this.loginRedirect} href="/">Login</a>;
        }
        return (
            <div>
                {link}
            </div>
        )
    }
}

export { LoginComponent }; 